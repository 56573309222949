import React, { useState } from "react"

import Cover from "../components/cover";
import Us from "../components/us";
import Header from "../components/es/header";

import SEO from "../components/seo";
import "./../styles/test.scss";
import Services from "../components/services";


import Contact from "../components/contact";
import Footer from "../components/footer";
import Customers from "../components/customers";

const TestPage = () => {

  let [currentPage, setCurrentPage] = useState("Inicio");
  let lang = "es";

  return (
    <div className = "index">

    <SEO 
      title="CIE Negocios "
        description="Consultoría profesional para negocios especializada. Somos un grupo de profesionistas y profesionales en diferentes áreas de Negocios, Administración, Finanzas, Legal, Legal-Corporativo, Apertura de Empresas, Impuestos que comprenden entre otros: análisis, determinación, cumplimiento de obligaciones, etc.; Fiscal: análisis de situación de empresas, procesos de cumplimiento de obligaciones y declaraciones, así como la defensa fiscal en todos los rubros; Dictámenes Fiscales SAT, Dictámenes Seguro Social, además de que somos expertos en el manejo de procesos de SATIC / SIROC con el IMSS, para Constructoras, entre otras."
    />

    <Header checked={currentPage} />

    <Cover 
      title="¿Buscas soluciones profesionales?"
      subtitle="Aquí las optimizaremos para ti."
      text="Tenemos soluciones integrales para su empresa que la llevarán al siguiente nivel."
      square1={{ title:"Asesoría especializada", text:"Nuestros expertos a disposición de su empresa."}}
      square2={{ title: "Resultados profesionales", text: "Calidad y resultados comprobados." }}
    />

    <Us lang={lang} />

    <Services lang={lang} />

    <Customers lang={lang} />

    <Contact lang={lang}/>

    <Footer lang={lang} />



  </div>
  )
}

export default TestPage;
